import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setUser } from '../redux/user';


import {getNoteList, playRandomInterval, playIntervalFromNames, playTest} from '../lib/Sounds';
import FeedbackIntervals from '../components/pages/IntervalGame/FeedbackIntervals';

import Vex from 'vexflow';

import '../css/IntervalGame.css';

import GuessView from '../components/pages/IntervalGame/GuessView'
import SettingsController from '../components/pages/IntervalGame/SettingsController'
import UnplayedView from '../components/pages/IntervalGame/UnplayedView'

import { setLongestStreakMelodicIntervalGame } from '../graphql/amplify';
import Navbar from '../components/Navbar';

const note_name_list = getNoteList()

const IntervalGame = (props) => {
    const dispatch = useDispatch();
    const [gameState, setGameState] = useState('not_played')
    const [answer, setAnswer] = useState()
    const [numCorrect, setNumCorrect] = useState(0)
    const [numIncorrect, setNumIncorrect] = useState(0)
    const [minNote, setMinNote] = useState(0)
    const [maxNote, setMaxNote] = useState(note_name_list.length-1)
    const [delay, setDelay] = useState(1)
    const [settingsVisible, setSettingsVisible] = useState(false) // State to manage visibility of SettingsController
    const [currentStreak, setCurrentStreak] = useState(0);

    let selected_intervals_initial_obj = {
        'ascending_unison': true,
        'ascending_major_third': true,
        'ascending_perfect_fourth': true,
        'ascending_perfect_fifth': true,
        'ascending_major_sixth': true,
        'ascending_minor_seventh': true,
        'ascending_octave': true,
        'descending_unison': false,
        'descending_major_third': true,
        'descending_perfect_fourth': true,
        'descending_perfect_fifth': true,
        'descending_major_sixth': false,
        'descending_minor_seventh': false,
        'descending_octave': true
    }
    const [selectedIntervals, setSelectedIntervals] = useState(selected_intervals_initial_obj)

    const instrument_list = [
        'Piano',
        'Clarinet'
    ]
    const [selectedInstrument, setSelectedInstrument] = useState(instrument_list[0]);

    // get user data from redux store
    // TODO move to app.js
    let user = useSelector((state) => state.user.user);

    let longestStreakTemp = 0;
    if(user?.longestStreakMelodicIntervalGame){
        longestStreakTemp = user.longestStreakMelodicIntervalGame;
    }

    const [longestStreak, setLongestStreak] = useState(longestStreakTemp);

    function handlePlayButton(){
        //clear music staff
        const staff = document.getElementById('music-staff');
        while (staff.hasChildNodes()) {
            staff.removeChild(staff.lastChild);
        }

        //format selected interval obj into array
        let selected_interval_array = []
        Object.keys(selectedIntervals).map(key =>{
            if(selectedIntervals[key]){
                selected_interval_array.push(key)
            }
        })

        //play random interval
        let solution = playRandomInterval(selected_interval_array,[minNote,maxNote],delay,selectedInstrument)
        //update state
        setAnswer(solution)
        setGameState('played')
    }

    function handleGuess(guess){
        console.log(answer)
        if(answer.selected_interval === guess){
            if (currentStreak+1 > longestStreak) {
                console.log('Setting high score:', user.username, currentStreak+1);
                setLongestStreakMelodicIntervalGame(user.username, currentStreak+1);
                setLongestStreak(currentStreak+1);
                const updatedUser = {
                    ...user,
                    longestStreakMelodicIntervalGame: currentStreak + 1
                };
                dispatch(setUser(updatedUser));
            }

            setNumCorrect(numCorrect+1)
            setGameState('correct')
            setCurrentStreak(currentStreak + 1);
        }
        else{
            // alert('Incorrect')
            setNumIncorrect(numIncorrect+1)
            setGameState('incorrect')
            setCurrentStreak(0); // Reset streak on incorrect answer
        }
        drawMusicStaff(answer.notes[0],answer.notes[1])
    }

    function handleReplay(){
        // console.log('nn',answer.notes)
        playIntervalFromNames(answer.notes[0],answer.notes[1],delay,selectedInstrument)
    }

    function drawMusicStaff(note1,note2){
        const staff = document.getElementById('music-staff');
        while (staff.hasChildNodes()) {
            staff.removeChild(staff.lastChild);
        }
        // Create an SVG renderer and attach it to the DIV element named "boo".
        var vf = new Vex.Flow.Factory({renderer: {elementId: 'music-staff'}});
        var score = vf.EasyScore();
        var system = vf.System();

        system.addStave({
            voices: [score.voice(score.notes(`${note1}/h, ${note2}`))]
        }).addClef('treble').addTimeSignature('4/4');
        
        vf.draw();
    }

    return (
        <>
        <Navbar />
        <div className='interval-game'>
            <h1>Interval Game</h1>
            <div className='header-section'>
                <div className='scoreboard'>
                    <label>Longest Streak:</label>
                    <p>{longestStreak}</p>
                    <label>Current Streak:</label>
                    <p>{currentStreak}</p>
                    <label>Correct:</label>
                    <p>{numCorrect}</p>
                    <label>Incorrect:</label>
                    <p>{numIncorrect}</p>
                </div>
                <button onClick={() => setSettingsVisible(!settingsVisible)}>
                    {settingsVisible ? 'Hide Settings' : 'Show Settings'}
                </button>
                {settingsVisible && (
                    <SettingsController
                        selectedIntervals={selectedIntervals}
                        setSelectedIntervals={setSelectedIntervals}
                        gameState={gameState}
                        setMinNote={setMinNote}
                        setMaxNote={setMaxNote}
                        delay={delay}
                        setDelay={setDelay}
                        instrument_list={instrument_list}
                        setSelectedInstrument={setSelectedInstrument}
                    />
                )}
           </div>


            {gameState === 'correct' ?
                <React.Fragment>
                    <h2> Correct! </h2>
                    <p>{answer.selected_interval}</p> 
                    <p>{answer.notes[0]},{answer.notes[1]}</p>
                </React.Fragment>
                : null
            }
            {gameState === 'incorrect' ?
                <React.Fragment>
                    <h2> Incorrect </h2>
                    <p>{answer.selected_interval}</p> 
                    <p>{answer.notes[0]},{answer.notes[1]}</p> 
                </React.Fragment>
                : null
            }

            <div id='music-staff' className='music-staff'></div>



            {gameState === 'played'? 
                <GuessView handleGuess={handleGuess} selectedIntervals={selectedIntervals} handleReplay={handleReplay}/>
                :
                <UnplayedView gameState={gameState} handleReplay={handleReplay} handlePlayButton={handlePlayButton}/>
            }

            {(gameState === 'correct' || gameState === 'incorrect') && (
                <FeedbackIntervals firstNote={answer.notes[0]} delay={delay} selectedInstrument={selectedInstrument}/>
            )}

        </div>
        </>

    );
}

export default IntervalGame;