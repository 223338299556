import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';

const PitchDetector = () => {
    const [pitch, setPitch] = useState(null);
    const [note, setNote] = useState(null);

    useEffect(() => {
        async function startPitchDetection() {
            // Request access to the microphone
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            
            // Create an audio context
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createMediaStreamSource(stream);
            
            // Create an analyser node
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 32768; // High resolution for better accuracy
            source.connect(analyser);
            
            const bufferLength = analyser.fftSize;
            const dataArray = new Float32Array(bufferLength);
            
            function applyHammingWindow(buffer) {
                const N = buffer.length;
                for (let i = 0; i < N; i++) {
                    buffer[i] *= 0.54 - 0.46 * Math.cos((2 * Math.PI * i) / (N - 1));
                }
            }

            function autoCorrelate(buffer, sampleRate) {
                // Apply windowing function
                applyHammingWindow(buffer);

                // Perform an auto-correlation on the buffer to find the pitch
                let SIZE = buffer.length;
                let MAX_SAMPLES = Math.floor(SIZE / 2);
                let bestOffset = -1;
                let bestCorrelation = 0;
                let rms = 0;
                let foundGoodCorrelation = false;
                let correlations = new Array(MAX_SAMPLES);
                
                for (let i = 0; i < SIZE; i++) {
                    let val = buffer[i];
                    rms += val * val;
                }
                rms = Math.sqrt(rms / SIZE);
                if (rms < 0.01) return -1; // Not enough signal
                
                let lastCorrelation = 1;
                for (let offset = 0; offset < MAX_SAMPLES; offset++) {
                    let correlation = 0;
                    
                    for (let i = 0; i < MAX_SAMPLES; i++) {
                        correlation += Math.abs((buffer[i]) - (buffer[i + offset]));
                    }
                    correlation = 1 - (correlation / MAX_SAMPLES);
                    correlations[offset] = correlation; // Store it, for the tweaking we need to do below.
                    if ((correlation > 0.9) && (correlation > lastCorrelation)) {
                        foundGoodCorrelation = true;
                        if (correlation > bestCorrelation) {
                            bestCorrelation = correlation;
                            bestOffset = offset;
                        }
                    } else if (foundGoodCorrelation) {
                        // Short-circuit - we found a good correlation, then a bad one, so we'd just be seeing copies from here.
                        let shift = (correlations[bestOffset + 1] - correlations[bestOffset - 1]) / correlations[bestOffset];
                        return sampleRate / (bestOffset + (8 * shift));
                    }
                    lastCorrelation = correlation;
                }
                if (bestCorrelation > 0.01) {
                    return sampleRate / bestOffset;
                }
                return -1;
            }

            function getNoteFromPitch(frequency) {
                const noteStrings = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
                const A4 = 440;
                const A4_INDEX = 69;
                const SEMITONES_IN_OCTAVE = 12;

                const noteIndex = Math.round(
                    SEMITONES_IN_OCTAVE * Math.log2(frequency / A4) + A4_INDEX
                );
                const note = noteStrings[noteIndex % SEMITONES_IN_OCTAVE];
                const octave = Math.floor(noteIndex / SEMITONES_IN_OCTAVE) - 1;

                return `${note}${octave}`;
            }
            
            function detectPitch() {
                analyser.getFloatTimeDomainData(dataArray);
                const pitch = autoCorrelate(dataArray, audioContext.sampleRate);
                if (pitch !== -1) {
                    setPitch(pitch);
                    setNote(getNoteFromPitch(pitch));
                } else {
                    setPitch(null);
                    setNote(null);
                }
                requestAnimationFrame(detectPitch);
            }
            
            detectPitch();
        }

        startPitchDetection();
    }, []);

    return (
        <>
            <Navbar />
            <div>
                <h1>Pitch Detector</h1>
                <p>Detected Pitch: {pitch ? `${pitch.toFixed(2)} Hz` : 'Listening...'}</p>
                <p>Musical Note: {note || 'Listening...'}</p>
            </div>
        </>
    );
};

export default PitchDetector;