import { gql } from '@apollo/client';

const getUser = gql`
    query GetUser($username: String!) {
        getUser(username: $username) {
            username
            longestStreakMelodicIntervalGame
        }
    }
`;

export { getUser };