import logo from './logo.svg';
import './App.css';

import { Routes, Route } from "react-router-dom";

import Navbar from './components/Navbar';
import Home from './pages/Home';
import IntervalGame from './pages/IntervalGame';
import HarmonicIntervalGame from './pages/HarmonicIntervalGame';
import PitchDetector from './pages/PitchDetector';
import Login from './pages/Login';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/interval" element={<ProtectedRoute><IntervalGame /></ProtectedRoute>} />
        <Route path="/harmonic-interval" element={<HarmonicIntervalGame />} />
        <Route path="/pitch-detector" element={<PitchDetector />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
