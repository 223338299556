import { gql } from '@apollo/client';

const setLongestStreakMelodicIntervalGame = gql`
    mutation SetLongestStreakMelodicIntervalGame($username: String!, $score: Int!) {
        setLongestStreakMelodicIntervalGame(username: $username, score: $score) {
            username
        }
    }
`;

const setLongestStreakHarmonicIntervalGame = gql`
    mutation SetLongestStreakHarmonicIntervalGame($username: String!, $score: Int!) {
        setLongestStreakHarmonicIntervalGame(username: $username, score: $score) {
            username
        }
    }
`;

export { setLongestStreakMelodicIntervalGame, setLongestStreakHarmonicIntervalGame };