import { generateClient } from 'aws-amplify/api';

import { getUser as getUserGql } from './queries';
import { setLongestStreakMelodicIntervalGame as setLongestStreakMelodicIntervalGameGql } from './mutations';
import { setLongestStreakHarmonicIntervalGame as setLongestStreakHarmonicIntervalGameGql } from './mutations';


const client = generateClient();

//#############################################
//#     Queries
//#############################################

const getUser = async (username) => {
    let result;
    try {
        result = await client.graphql({
            query: getUserGql,
            variables: { username }
        });
        result = result.data.getUser;
    }
    catch (err) {
        console.error('Error:', err);
    }
    finally {
        return result;
    }
}

//#############################################
//#     Mutations
//#############################################

const setLongestStreakMelodicIntervalGame = async (username, score) => {
    let result;
    try {
        result = await client.graphql({
            query: setLongestStreakMelodicIntervalGameGql,
            variables: { username, score }
        });
        console.log('Result:', result);
    }
    catch (err) {
        console.error('Error:', err);
    }
    finally {
        return result;
    }
}

const setLongestStreakHarmonicIntervalGame = async (username, score) => {
    let result;
    try {
        result = await client.graphql({
            query: setLongestStreakHarmonicIntervalGameGql,
            variables: { username, score }
        });
        console.log('Result:', result);
    }
    catch (err) {
        console.error('Error:', err);
    }
    finally {
        return result;
    }
}


export { getUser, setLongestStreakMelodicIntervalGame, setLongestStreakHarmonicIntervalGame };

