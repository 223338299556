import React from "react";

const GuessView = (props) => {
    return (
        <React.Fragment>
            <div className='guess-btn-group'>
                {props.selectedIntervals['major_third'] ? <button className='default-button' onClick={() => props.handleGuess('major_third')}>Major Third</button> : null}
                {props.selectedIntervals['perfect_fifth'] ? <button className='default-button' onClick={() => props.handleGuess('perfect_fifth')}>Perfect Fifth</button> : null}
                {props.selectedIntervals['minor_seventh'] ? <button className='default-button' onClick={() => props.handleGuess('minor_seventh')}>Minor Seventh</button> : null}
                {props.selectedIntervals['octave'] ? <button className='default-button' onClick={() => props.handleGuess('octave')}>Octave</button> : null}
            </div>
            <div>
                <button className='default-button' onClick={props.handleReplay}>Replay</button>
            </div>
        </React.Fragment>
    )
}

export default GuessView;