import '../css/Navbar.css'

import { Link, useMatch, useResolvedPath } from "react-router-dom"
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';



import styles from '../css/Navbar.module.css';

import { login, logout } from '../redux/auth'; // Path to your auth slice
import { signOut } from 'aws-amplify/auth';


export default function Navbar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isHamburgerExpanded, setIsHamburgerExpanded] = useState(false)
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const [isGamesDropdownVisible, setIsGamesDropdownVisible] = useState(false)


    const handleLogout = async () => {
        try {
            await signOut();
            dispatch(logout());
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Error signing out:', error);
        }
    }

    return (
        <React.Fragment>
            <nav className="nav sticky">
                <Link to="/" className={styles.site_title}>Ear Trainer</Link>
                {/* <Link to='/'>
                    <img className='nav-logo' src='./klubby.png' />
                </Link> */}

                <button className="hamburger">
                    {/* icon from heroicons.com */}
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                    onClick={() => {
                        setIsHamburgerExpanded(!isHamburgerExpanded)
                    }}
                    >
                        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRul="evenodd"/>
                    </svg>
                </button>

                <ul className={isHamburgerExpanded ? "expanded" : null}>
                    <CustomLink to='/'>Home</CustomLink>

                    <li className={styles.dropdown} >
                        <button
                            className={styles.dropdown_button}
                            onClick={() => setIsGamesDropdownVisible(!isGamesDropdownVisible)}
                        >
                            Games
                        </button>
                        {isGamesDropdownVisible && (
                            <ul className={styles.dropdown_menu}>
                                <CustomLink to='/interval'>Interval Game</CustomLink>
                                <CustomLink to='/harmonic-interval'>Harmonic Interval Game</CustomLink>
                                <CustomLink to='/pitch-detector'>Pitch Detector Game</CustomLink>
                            </ul>
                        )}
                    </li>

                    <div className={styles.user_icon}>
                        <img
                            src="/user-icon.png"
                            alt="User Icon"
                            className={styles.user_icon}
                            onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                        />
                        {isDropdownVisible && (
                            <div className={styles.dropdown_menu}>
                                {/* <Link to="/profile">Profile</Link> */}
                                {/* <Link to="/settings">Settings</Link> */}
                                <button onClick={handleLogout}>Logout</button>
                            </div>
                        )}
                    </div>
                </ul>
            </nav>
            <div className={styles.nav_padding} />
        </React.Fragment>
    )
}

function CustomLink({to, children, ...props}){
    //get current full resolved path
    const resolvedPath = useResolvedPath(to)
    //detect if page is active
    const isActive = useMatch({ path: resolvedPath.pathname, end: true})

    return (
        <li className={isActive ? "active" : null}>
            <Link to={to}>{children}</Link>
        </li>
    )
}