import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux';

import { setUser } from '../redux/user';


import {getNoteList, playRandomHarmonicInterval, playHarmonicIntervalFromNames} from '../lib/Sounds'
import Vex from 'vexflow'

import GuessView from '../components/pages/HarmonicIntervalGame/GuessView'
import SettingsController from '../components/pages/HarmonicIntervalGame/SettingsController'
import FeedbackIntervals from '../components/pages/HarmonicIntervalGame/FeedbackIntervals';
import Navbar from '../components/Navbar';

import styles from '../css/HarmonicIntervalGame.module.css';

import { setLongestStreakHarmonicIntervalGame } from '../graphql/amplify';


const note_name_list = getNoteList()

const HarmonicIntervalGame = (props) => {
    const dispatch = useDispatch();
    const [gameState, setGameState] = useState('not_played')
    const [answer, setAnswer] = useState()
    const [numCorrect, setNumCorrect] = useState(0)
    const [numIncorrect, setNumIncorrect] = useState(0)
    const [minNote, setMinNote] = useState(0)
    const [maxNote, setMaxNote] = useState(note_name_list.length-1)
    const [currentStreak, setCurrentStreak] = useState(0);


    let selected_intervals_initial_obj = {
        'major_third': true,
        'perfect_fifth': true,
        'minor_seventh': true,
        'octave': true
    }
    const [selectedIntervals, setSelectedIntervals] = useState(selected_intervals_initial_obj)


    const instrument_list = [
        'Piano',
        'Clarinet'
    ]
    const [selectedInstrument, setSelectedInstrument] = useState(instrument_list[0]);


    // get user data from redux store
    // TODO move to app.js
    let user = useSelector((state) => state.user.user);

    let longestStreakTemp = 0;
    if(user?.longestStreakHarmonicIntervalGame){
        longestStreakTemp = user.longestStreakHarmonicIntervalGame;
    }

    const [longestStreak, setLongestStreak] = useState(longestStreakTemp);

    function handlePlayButton(){
        //clear music staff
        const staff = document.getElementById('music-staff');
        while (staff.hasChildNodes()) {
            staff.removeChild(staff.lastChild);
        }

        //format selected interval obj into array
        let selected_interval_array = []
        Object.keys(selectedIntervals).map(key =>{
            if(selectedIntervals[key]){
                selected_interval_array.push(key)
            }
        })

        //play random interval
        let solution = playRandomHarmonicInterval(selected_interval_array,[minNote,maxNote])
        //update state
        setAnswer(solution)
        setGameState('played')
    }

    function handleGuess(guess){
        console.log(answer)
        if(answer.selected_interval === guess){
            if (currentStreak+1 > longestStreak) {
                console.log('New longest streak!', currentStreak+1);
                setLongestStreak(currentStreak+1)
                setLongestStreakHarmonicIntervalGame(user.username, currentStreak+1)
                const updatedUser = {
                    ...user,
                    longestStreakHarmonicIntervalGame: currentStreak + 1
                };
                dispatch(setUser(updatedUser));
            }


            setNumCorrect(numCorrect+1)
            setGameState('correct')
            setCurrentStreak(currentStreak + 1);
        }
        else{
            // alert('Incorrect')
            setNumIncorrect(numIncorrect+1)
            setGameState('incorrect')
            setCurrentStreak(0); // Reset streak on incorrect answer
        }
        drawMusicStaff(answer.notes[0],answer.notes[1])
    }


    function handleReplay(){
        playHarmonicIntervalFromNames(answer.notes[0],answer.notes[1])
    }

    function handleClickIntervalCheckbox(event){
        //make copy of selected intervals obj
        let copy_selectedIntervals = {...selectedIntervals}
        //update state
        copy_selectedIntervals[event.target.value] = event.target.checked
        setSelectedIntervals(copy_selectedIntervals)
    }

    function drawMusicStaff(note1,note2){
        const staff = document.getElementById('music-staff');
        while (staff.hasChildNodes()) {
            staff.removeChild(staff.lastChild);
        }
        // Create an SVG renderer and attach it to the DIV element named "boo".
        var vf = new Vex.Flow.Factory({renderer: {elementId: 'music-staff'}});
        var score = vf.EasyScore();
        var system = vf.System();

        system.addStave({
            voices: [score.voice(score.notes(`${note1}/h, ${note2}`))]
        }).addClef('treble').addTimeSignature('4/4');
        
        vf.draw();
    }


    let option_list = []
    for(let i in note_name_list){
        option_list.push({value: i,label: note_name_list[i]})
    }


    return (
        <>
        <Navbar />
        <div className={styles.harmonic_interval_game}>
            <h1>Harmonic Interval Game</h1>
            <div className={styles.header_section}>
                <div className={styles.scoreboard}>
                    <label>Longest Streak:</label>
                    <p>{longestStreak}</p>
                    <label>Current Streak:</label>
                    <p>{currentStreak}</p>
                    <label>Correct:</label>
                    <p>{numCorrect}</p>
                    <label>Incorrect:</label>
                    <p>{numIncorrect}</p>
                </div>
                <SettingsController selectedIntervals={selectedIntervals} setSelectedIntervals={setSelectedIntervals} gameState={gameState} setMinNote={setMinNote} setMaxNote={setMaxNote} instrument_list={instrument_list} setSelectedInstrument={setSelectedInstrument}/>
            </div>


            {gameState === 'correct' ?
                <React.Fragment>
                    <h2> Correct! </h2>
                    <p>{answer.selected_interval}</p> 
                    <p>{answer.notes[0]},{answer.notes[1]}</p>
                </React.Fragment>
                : null
            }
            {gameState === 'incorrect' ?
                <React.Fragment>
                    <h2> Incorrect </h2>
                    <p>{answer.selected_interval}</p> 
                    <p>{answer.notes[0]},{answer.notes[1]}</p> 
                </React.Fragment>
                : null
            }

            <div id='music-staff' className={styles.music_staff}></div>



            {gameState === 'played'? 
                <GuessView selectedIntervals={selectedIntervals} handleGuess={handleGuess} handleReplay={handleReplay}/>
                : 
                <button className={styles.play_button} onClick={handlePlayButton}>Play</button>
            }

            {(gameState === 'correct' || gameState === 'incorrect') && (
                <FeedbackIntervals firstNote={answer.notes[0]} secondNote={answer.notes[1]} selectedInstrument={selectedInstrument}/>
            )}

        </div>
        </>
    );
}

export default HarmonicIntervalGame;