import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { setUser } from '../redux/user';
import { getUser } from '../graphql/amplify';
import '../css/Home.css';
import Navbar from '../components/Navbar';


const Home = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Get the username from the Redux store
    const username = useSelector((state) => state.auth.user?.username);

    useEffect(() => {
        if(username) {
            getUser(username)
                .then((data) => {
                    // save user data to redux store
                    dispatch(setUser(data));
                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error:', err);
                    setError(err);
                });
        }
    }, [username]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
        <Navbar />
        <div className="home-container">
            <h1>Ear Trainer Central</h1>
            {username && <h2>Welcome, {username}!</h2>}
            <h4>The following is a set of games designed to help musically train your ear.</h4>

            <div className="game-container">
                <Link className="game-title" to="interval">Interval Game</Link>
                <p className="game-description">Hear 2 notes and identify the played interval.</p>
            </div>
            <div className="game-container">
                <Link className="game-title" to="harmonic-interval">Harmonic Interval Game</Link>
                <p className="game-description">Hear 2 notes played simultaneously and identify the played harmonic interval.</p>
            </div>
            <div className="game-container">
                <Link className="game-title" to="interval-comparison">Interval Comparison Game</Link>
                <p className="game-description">Hear 2 to 3 different intervals and select the largest/smallest interval.</p>
            </div>
            <div className="game-container">
                <Link className="game-title" to="pitch-detector">Pitch Detector Game</Link>
                <p className="game-description">Tells you what note you are singing.</p>
            </div>
        </div>
        </>
    );
}

export default Home;