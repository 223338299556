import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, signIn, signUp } from 'aws-amplify/auth';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../redux/auth'; // Path to your auth slice
import { useNavigate } from 'react-router-dom';

Amplify.configure(
{
    Auth: {
        Cognito: {
            region: 'us-east-1',
            userPoolId: process.env.REACT_APP_USER_POOL_ID,
            userPoolClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,
            username: 'true',
        }
    },
    API: {
        GraphQL: {
          endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
          region: 'us-east-1',
          defaultAuthMode: 'userPool',
        }
    }
    // config_userPool,
});
  
const Login = () => {
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);

    // TODO move to app.js
    useEffect(() => {
        const checkUser = async () => {
            // check if user is already signed in
            const currentUser = await getCurrentUser();
            if (currentUser) {
                console.log('User is already signed in:', currentUser);
                dispatch(login({ username: currentUser.username }));
                navigate('/', { replace: true });
                return;
            }
        };

        checkUser();
    }, []); // empty dependency array to run only once on mount

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        try {
            const { isSignedIn, nextStep } = await signIn({ username, password });
            console.log('User details:', { isSignedIn, nextStep });

            if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                setError('Please check your email to confirm your account.');
            }
            // else if (nextStep === 'NEW_PASSWORD_REQUIRED') {
            else if (isSignedIn && nextStep.signInStep === 'DONE') {
                setSuccess('Login successful!');
                dispatch(login({ username, nextStep}));
                navigate('/', { replace: true });
            }
        } catch (err) {
            setError(err.message || 'Error logging in');
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        try {
            const { user } = await signUp({
                username,
                password,
                options: {
                    userAttributes: {
                        email,
                    }
                }
            });
            setSuccess('Sign up successful! Please check your email to confirm your account.');
        } catch (err) {
            setError(err.message || 'Error signing up');
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: 'auto', padding: '20px' }}>
            <h2>{isSignUp ? 'Sign Up' : 'Login'}</h2>
            <form onSubmit={isSignUp ? handleSignUp : handleLogin}>
                <div>
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        style={{ width: '100%', padding: '8px', margin: '10px 0' }}
                    />
                </div>
                <div>
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        style={{ width: '100%', padding: '8px', margin: '10px 0' }}
                    />
                </div>
                {isSignUp && (
                    <div>
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={{ width: '100%', padding: '8px', margin: '10px 0' }}
                        />
                    </div>
                )}
                <button type="submit">{isSignUp ? 'Sign Up' : 'Login'}</button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}
            <button onClick={() => setIsSignUp(!isSignUp)} style={{ marginTop: '10px' }}>
                {isSignUp ? 'Already have an account? Login' : 'Don\'t have an account? Sign Up'}
            </button>
        </div>
    );
};


export default Login;
